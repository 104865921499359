.nav-link {
  cursor: pointer;
}

.injuryGrid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 900px;
}

.grid {
  display: flex;
  /*flex-direction: column;*/
  /*flex-wrap: wrap;*/
}

.grid-surveys {
  width: 600px;
}

.child-sport-form-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.child-sport-form-grid > * {
  margin-right: 25px;
  margin-bottom: 25px;
}

.childSportForm {
  display: flex;
  flex-direction: column;
  transform: scale(0.8);
}

@media only screen and (max-width: 1200px) {
  .injuryGrid {
    flex-direction: row;
    height: auto;
  }
  .grid {
    flex-direction: row;
    height: auto !important;
  }
  .grid-surveys {
    width: 700px;
  }
}

@media only screen and (max-width: 1000px) {
  .grid {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.sport-form-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  max-width: 278px;
}
.sport-form-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@media only screen and (max-width: 767px) {
  .sport-form-card {
    max-width: 100%;
  }
}
